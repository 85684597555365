import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import ParticleImage from '@/components/common/ParticleImage'
import Typography from '@/components/common/Typography'
import { desktop, laptop, tablet, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq, matchMedia, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import imgKvLarge from '@/images/kv-large.png'
import imgKvSmall from '@/images/kv-small.png'

class KV extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false
    }
  }
  componentDidMount () {
    this.setState({
      mounted: true
    })
  }
  render () {
    return (
      <Root windowInitialized={this.props.windowInitialized} windowHeight={this.props.windowHeight} scrollTop={this.props.scrollTop}>
        <Copy>
          <Image src={matchMedia(this.props.mediaType)(mobile) ? imgKvSmall : imgKvLarge}/>
        </Copy>
        <Message variant="h1" mounted={this.state.mounted}>受託開発 ･ 運用実績2,000件以上。私たちは、使い手の未来を作るためにシステムを開発し続けています。</Message>
        <Scroll windowInitialized={this.props.windowInitialized} windowHeight={this.props.windowHeight} scrollTop={this.props.scrollTop} mounted={this.state.mounted}>
          <Scroll_Text variant="h5" lang="en">SCROLL</Scroll_Text>
          <Scroll_Arrow>
            <div></div>
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 6L12 0L0 0L6 6Z" fill="currentColor"/>
            </svg>
          </Scroll_Arrow>
        </Scroll>
      </Root>
    )
  }
}
const mapStateToProps = state => {
  return {
    windowHeight: state.UI.Window.fullHeight,
    windowInitialized: state.UI.Window.initialized,
    scrollTop: state.UI.Window.scrollTop,
    mediaType: state.UI.Window.mediaType
  }
}

export default ThemeConsumer(connect(mapStateToProps)(KV))

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${mq.and(desktop, laptop)} {
    height: ${props => {
      if (props.windowHeight) {
        return props.windowHeight - 128 + 'px'
      }
      if (typeof window !== 'undefined' && window.initialSize) {
        return window.initialSize.height - 128 + 'px'
      }
      return 'calc(100vh - 128px)'
    }};
    min-height: 800px;
    padding-top: 40px;
    padding-bottom: 120px;
    justify-content: center;
  }
  @media ${mq.and(tablet)} {
    justify-content: center;
    padding: 100px 0 200px;
  }
  @media ${mq.and(mobile)} {
    justify-content: center;
    padding: 20px 0 160px;
  }
  @media ${mq.and(tablet, mobile)} {
    min-height: ${props => {
      if (props.windowHeight) {
        return props.windowHeight - 64 + 'px'
      }
      if (typeof window !== 'undefined' && window.initialSize) {
        return window.initialSize.height - 64 + 'px'
      }
      return 'calc(100vh - 128px)'
    }};
  }
`

const Copy = styled.div`
  @media ${mq.and(desktop, laptop, tablet)} {
    ${aspectRatio(935, 327)};
  }
  @media ${mq.and(mobile)} {
    ${aspectRatio(672, 277)};
    max-width: 520px;
    margin-left: ${layouts.defs.pad.mobile * -1}px;
    margin-right: ${layouts.defs.pad.mobile * -1}px;
  }
`

const Image = styled(ParticleImage)`
  ${aspectRatioChild};
`

const Message = styled.h1`
  position: relative;
  margin-top: -46px;
  margin-left: auto;
  margin-right: 0;
  width: 768px;
  max-width: 100%;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;
  @media ${mq.and(tablet)} {
    margin-top: 40px;
    padding-left: 90px;
    font-size: 32px;
  }
  @media ${mq.and(tabletS)} {
    margin-top: 40px;
    padding-left: 90px;
    font-size: 28px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
    padding-left: 90px;
    font-size: 20px;
  }
  @media ${mq.and(mobileS)} {
    padding-left: 24px;
    font-size: 18px;
  }
  transition: opacity 0.2s ease;
  opacity: ${props => props.mounted
    ? 1
    : 0
  };
`

const Scroll = styled.div`
  display: ${props => props.windowInitialized ? 'block' : 'none'};
  position: fixed;
  left: 50%;
  @media ${mq.and(desktop, laptop, tabletL)} {
    bottom: 32px;
  }
  @media ${mq.and(tabletS, mobile)} {
    bottom: 24px;
  }
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.2s ease;
  opacity: ${props => props.scrollTop <= 0
    ? props.mounted ? 1 : 0
    : 0
  };
  @media ${mq.and(desktop)} and (max-height: 1023px) {
    display: none;
  }
  @media ${mq.and(laptop)} and (max-height: 1023px) {
    display: none;
  }
  @media ${mq.and(tablet)} and (orientation: landscape) {
    display: none;
  }
  @media ${mq.and(mobile)} and (orientation: landscape) {
    display: none;
  }
`

const Scroll_Text = styled(Typography)``

const Scroll_Arrow = styled.div`
  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(4px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation-name: updown;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`
