import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Image from '@/components/common/Image'
import Typography from '@/components/common/Typography'
import LinkButton1 from '@/components/common/LinkButton1'
import ParticleBackground from '@/components/common/ParticleBackground'

const ProductLeadCard = props => (
  <Root theme={props.theme}>
    <Left start={props.color.start} stop={props.color.stop}>
      <Background scale={props.color.type === 'light' ? 900 : 400} />
      <Logo fluid={props.image.mainLogo.childImageSharp.fluid} />
      <Screenshot fluid={props.image.screenshot.childImageSharp.fluid} />
    </Left>
    <Right color={props.theme.scale[50]}>
      <Right_Main>
        <Name variant="h3" component="h4" type="contrast">{props.name}</Name>
        <Description variant="body1" type="contrast">{props.description}</Description>
      </Right_Main>
      <Right_Foot>
        <Button component="a" href={props.link} target="_blank" type="contrast">製品ページへ</Button>
      </Right_Foot>
    </Right>
  </Root>
)

export default ThemeConsumer(ProductLeadCard)

const Root = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: stretch;
  }
`

const Left = styled.div`
  position: relative;
  background: linear-gradient(
    254.09deg, ${props => props.start} 0%, ${props => props.stop} 100%
  );
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    flex-shrink: 0;
    padding: 80px 40px 40px;
  }
  @media ${mq.and(tabletS, mobile)} {
    padding: 40px 24px 24px;
  }
`

const Right = styled.div`
  background-color: ${props => props.color};
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    flex-shrink: 0;
    padding: 40px;
  }
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Right_Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Right_Foot = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    text-align: right;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
    text-align: center;
  }
`

const Background = styled(ParticleBackground)`
  position:  absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Logo = styled(Image)`
  margin: 0 auto;
  width: 100%;
  max-width: 296px;
  @media ${mq.and(mobile)} {
    max-width: 176px;
  }
`

const Screenshot = styled(Image)`
  margin: 32px auto 0;
  width: 100%;
  max-width: 400px;
`

const Name = styled(Typography)`
`

const Description = styled(Typography)`
  margin-top: 24px;
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`
