import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery, Link } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tablet, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import ClientCard from './ClientCard'
import LinkButton1 from '@/components/common/LinkButton1'

const ServiceLead = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const clients = data.allClientListYaml.edges.map(el => el.node)
      return (
        <Root theme={props.theme}>
          <Main>
            <Left>
              <Section
                subtitle="SERVICES"
                title="お客さまのビジネスに合わせた、最適なシステムをカタチにします。"
                body="当社は、これまで20年間システム導入計画の策定から構築・運用サポートに至るまで、最先端の技術力を提供してきました。その技術がお客様から高評価を頂き、大手企業様との直接取引でお仕事をいただいてきました。"
              />
              <Button1 component={Link} to="/services/" scale="800">さらに詳しく</Button1>
            </Left>
            <Right>
              <ClientList>
                <ClientList_Parent>
                  {clients.map((client, index) => (
                    <ClientList_Child key={index}>
                      <ClientCard {...client} />
                    </ClientList_Child>
                  ))}
                </ClientList_Parent>
              </ClientList>
            </Right>
          </Main>
          <Foot>
            <Button2 component={Link} to="/services/" scale="800">さらに詳しく</Button2>
          </Foot>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(ServiceLead)

const Root = styled.div`
`

const Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Left = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    padding-right: 40px;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
  }
`

const Foot = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
    text-align: center;
  }
`

const ClientList = styled.div`
`

const ClientList_Parent = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -16px -16px 0;
  @media ${mq.and(tablet, mobile)} {
    margin: -8px -4px 0;
  }
`

const ClientList_Child = styled.li`
  margin-top: 16px;
  padding: 0 16px;
  @media ${mq.and(tablet, mobile)} {
    margin-top: 8px;
    padding: 0 4px;
  }
  width: ${100 / 3}%;
`

const Button1 = styled(LinkButton1)`
  margin-top: 64px;
  width: 278px;
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }
`

const Button2 = styled(LinkButton1)`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const query = graphql`
  query ServiceLeadQuery {
    allClientListYaml(
      filter: { main: { eq: true } }
    ) {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
